/* Banner Section Styles */
.banners {
  margin-bottom: 40px;
 
  width: 140vh;  
}

/* Style for individual banner items */
.banner-item {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Image Styling for Attractive Appearance */
.img-fluid {
  border-radius: 4vh;               /* Rounded corners for a smoother look */
  height: 30vh; 
                  /* Fixed height for the images */
  object-fit: cover;                /* Ensures images cover the area without distortion */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
  box-shadow: 0 4px 20px rgba(255, 253, 253, 0.1); /* Soft shadow for depth */
  transform: scale(1);   
   border: 2px solid white;         /* Initial scale, normal size */
  opacity: 0;                        /* Initially hidden for animation */
  animation: fadeInEffect 1.5s forwards; /* Fade in animation when the image loads */
}

/* Keyframe Animation for Image Fade-in */
@keyframes fadeInEffect {
  0% {
    opacity: 0;
    transform: translateY(30px);     /* Start from below the screen */
  }
  100% {
    opacity: 1;
    transform: translateY(0);        /* End at its normal position */
  }
}

/* Hover Effects for Interactive Images */
.img-fluid:hover {
  transform: scale(1.05);            /* Slight zoom effect on hover */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Responsive Design for smaller screens */


.img-thumbnail{
  height: 90vh;
}

.banner-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Animation for Banner Image */
.animated-banner {
  opacity: 0;
  animation: fadeInBanner 1.5s forwards;
}

@keyframes fadeInBanner {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .col-md-3 {
    margin-bottom: 20px;
  }

  .img-fluid {
    height: 24vh;                   /* Adjust height for smaller screens */
    border-radius: 10px; 
    margin-bottom: 20px;  
    
   
     
  
}

.img-thumbnail{
  height: 40vh;
  object-fit: cover;
  width: 50vh;
}
.m{
  text-align: center;
  margin-left: 4px !important;
  margin-right: -30px !important;
}

.banners{
width: 30vh;
margin-left: 8vh;


}
.sm-megamenu-hover sm_megamenu_menu sm_megamenu_menu_black sdfgfd{
  width: 40vh;
}
}

/* Add some margin to the top of the section */
.slider-area {
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Additional animation for buttons and headings */
.btn {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}
.img-circle{
  height: 20vh;
  width: 50vh;
}

/* Animation for text */
.tlt {
  font-size: 30px;
  font-weight: bold;
  margin-top: 20px;
  text-transform: uppercase;
}

.tlt span {
  color: #007bff;
}

.wow.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.sm-megamenu-hover {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
}

.sm_megamenu_menu li {
  display: inline-block; /* Makes the items appear in a line */
  margin-right: 20px; /* Adds space between items */
}

.sm_megamenu_menu a {
  display: flex;
  align-items: center; /* Center the icon and text vertically */
  color: #333; /* Text color */
  text-decoration: none; /* Removes the underline */
  font-size: 18px; /* Set a base font size */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.sm_megamenu_menu a i {
  margin-right: 8px; /* Adds space between the icon and the text */
  font-size: 22px; /* Makes the icon a bit larger */
  color: #007bff; /* Telegram blue color */
}

.sm_megamenu_menu a:hover {
  color: #fff; /* Changes text color on hover */
  background-color: #007bff; /* Telegram blue background on hover */
  border-radius: 5px; /* Rounded corners for the background */
  padding: 8px 12px; /* Adds some padding around the text */
}

.sm_megamenu_menu a:hover i {
  color: #fff; /* Change the icon color on hover */
}

.sm_megamenu_menu li:last-child {
  margin-right: 0; /* Removes margin from the last item */
}
