.header {
    background-color: #000;
    text-align: center;
    padding: 20px;
  }
  
  .logo {
    font-size: 30px;
    font-weight: bold;
    color: gold;
  }
  